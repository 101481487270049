<template>


  <v-container fluid class="pa-0">
    <CarouselContainer />
    <!-- <CarouselContainer :banners="banners1" /> -->
    <v-row justify="center" class="mt-5 pa-3" no-gutters>
      <v-col cols="12" md="6" sm="12">

        <transition name="scroll-right" appear>
          <h1 class="text-primary text-center">เกี่ยวกับเรา</h1>
        </transition>
        <p class="text-justify">
          เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์
          การทำงานในสายอาชีพนี้มาพร้อมกับความรับผิดชอบที่ยิ่งใหญ่
          เรายินดีให้บริการคำปรึกษาและแนะนำผลิตภัณฑ์ประกันที่ตอบโจทย์ความต้องการของลูกค้าแต่ละท่าน
          เราเชื่อว่าการเป็นตัวแทนประกันไม่ใช่แค่การขายกรมธรรม์ แต่คือการสร้างความสัมพันธ์และความไว้วางใจกับลูกค้า
          และเชื่อว่าการวางแผนประกันภัยเป็นเรื่องสำคัญในการสร้างความมั่นคงทางการเงินและการป้องกันความเสี่ยงที่อาจเกิดขึ้นในชีวิต
          เราพร้อมให้คำปรึกษาที่เป็นกันเองและเข้าใจง่าย ไม่ว่าจะเป็นการเลือกประกันชีวิต ประกันสุขภาพ
          หรือการวางแผนป้องกันรายได้ในอนาคต ด้วยความรู้และประสบการณ์
          เรามุ่งมั่นที่จะช่วยให้คุณได้รับข้อมูลที่ถูกต้องและเป็นประโยชน์ เพื่อการตัดสินใจที่ดีที่สุดสำหรับคุณ
        </p>
       
      </v-col>
    </v-row>

    <FeedContainer />
    <v-row class="mb-5">
      <v-col justify="center" class="text-center" cols="12"><v-btn color="primary"  @click="navigateToCalPremium">
          คำนวณเบี้ยประกัน
          คลิกเลย
        </v-btn></v-col>
    </v-row>

   
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
import axios from '../plugins/axios'; // Import the custom axios instance
import logoPath from '../assets/logo.png';
import CarouselContainer from './CarouselBanner.vue';
import FeedContainer from './FeedBanner.vue';


export default {
  mounted() {
    document.title = "AIA Prime One"
    const metaTags = [
      { property: "og:title", content: "AIA Prime One" },
      { property: "og:description", content: "AIAPrimeONE เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์" },
      { property: "og:url", content: "https://aiaprimeone.com" },
      { property: "og:type", content: "website" }
    ];

    metaTags.forEach(tag => {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("property", tag.property);
      metaElement.setAttribute("content", tag.content);
      document.head.appendChild(metaElement);
    });
  },
  setup() {

    const drawer = ref(false);
    const snackbar = ref(false); // Controls the visibility of the snackbar
    const snackbarMessage = ref(''); // Holds the message to display in the snackbar
    const target = ref(null);
    const appBarHeight = 64;
    


  
    onMounted(() => {
    });
    onUnmounted(() => {
    });


    return {
      drawer,
      snackbar,
      snackbarMessage,
      appBarHeight
    };
  },
  computed: {
  },
  components: { CarouselContainer, FeedContainer },
  data() {
    return {
      logo: logoPath,
      // banners1: [
      //   {
      //     src: '/banner-master.jpg',
      //     // title: 'Welcome to our site',
      //     // description: 'This is the first banner description',
      //   },
      //   {
      //     src: '/banner-agent.jpg',
      //     // title: 'Discover our products',
      //     // description: 'This is the second banner description',
      //   },

      // ]

    };

  },

  methods: {
    navigateToCalPremium() {
      this.$router.push('/cal-premium'); // Ensure your router is set up for this path
    },
  }
};
</script>


<style scoped>
.v-card {
  margin-bottom: 1rem;
}

.header-cell {
  height: 200px;
  /* Set a fixed height for each header cell */
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make header-content take the full height of the header cell */
  align-items: center;
  text-align: center;
}

.plan-button {
  margin-top: auto;
  /* Ensures the button sticks to the bottom */
  width: 100px;
  /* Optional: set a consistent width for the button */
  max-width: 100%;
}

.information_description {
  min-width: 150px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease;
  /* Adjust speed and easing here */
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}
</style>