import {createRouter, createWebHistory } from 'vue-router'

import HomeView from './components/HomeView.vue'
import FormInformationView from './components/FormInformationView.vue'
import CalPremiumView from './components/CalPremiumView.vue'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    {
        path: '/form-information',
        name: 'Form Information',
        component: FormInformationView,
    },
    {
      path: '/cal-premium',
      name: 'Calculation Premium',
      component: CalPremiumView,
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   component: TestView,
    // },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router