<template>
  <v-row justify="center" class=" bg-lightgray pt-3 pb-3"  no-gutters>
    <v-col cols="12" md="6" sm="12">
          <v-row no-gutters>
            <v-col v-for="(banner, index) in banners" cols="6" md="4"  :key="index" class="pa-2">
              <v-card  @click="$router.push({ path: banner.link })" elevation="16" >
                <v-img
                  :src="banner.src"
                ></v-img>
                <!-- <v-card-title>Nature Image</v-card-title>
                <v-card-subtitle>Beautiful view of nature</v-card-subtitle>
                <v-card-actions>
                  <v-btn color="primary" text>View</v-btn>
                  <v-btn color="secondary" text>Share</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
</template>
<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
export default {
  setup() {
    // const carouselRef = ref(null);
    onMounted(() => {

    });

    return {
      banners: [
        {
          src: '/feed-banner/aia-health-happy1.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-health-kids.jpg',
          link: '/aia-health-kids'
        },
        {
          src: '/feed-banner/aia-endowment-1525-1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-day-surgery.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-discount.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare1.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-5reason.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare3.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare4.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-ci-procare5.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protection-65.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-happy2.jpg',
          link: '/aia-health-happy'
        },
        {
          src: '/feed-banner/aia-infinite-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-health-saver.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-health-saver2.jpg',
          link: '/aia-health-saver'
        },
        {
          src: '/feed-banner/aia-annuity-sure.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-3health-care.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-hand-foot-mouth-disease.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-2.jpg',
          link: '/'
        },
        {
          src: '/feed-banner/aia-protectoin-65-3.jpg',
          link: '/'
        },
      ],
      
    }
    
  },
  // props: {
  //   banners: {
  //     type: Array,
  //     // Arrays and Objects must return factory functions instead of
  //     // literal values. You can't do `default: []`, you have to do
  //     // `default: function() { return []; }`... what I wrote was the 
  //     // short-hand for how to do it with ES6 fat-arrow functions
  //     default: () => ([]) 
  //   }
  // }
}

</script>
            
<style>


</style>