<template>
    <v-container>
        <v-row><v-col><v-img :src="bannerApplicationForm" height="auto"></v-img></v-col></v-row>
        <v-row><v-col><h1 class="text-primary">กรอกข้อมูลของท่าน สมัครประกันบริษัท AIA ส่งให้ตัวแทน</h1></v-col></v-row>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submitForm">
            <v-row>
                 <!-- ID Card File -->
                 <v-col cols="12" class="pb-0 pt-0">
                    <v-file-input v-model="idCardFile" label="ถ่ายบัตรประชาชน (ถ้าไม่สะดวกพิมพ์ข้อมูลบัตร)" accept=".jpg,.jpeg,.png,.pdf"
                        @change="uploadFile"></v-file-input>
                </v-col>

                <!-- Full Name (Thai) -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="fullNameThai" label="ชื่อ-นามสกุล ภาษาไทย ตามบัตรประชาชน"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- Full Name (English) -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="fullNameEnglish" label="ชื่อ-นามสกุล ภาษาอังกฤษ ตามบัตรประชาชน"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- Height -->
                <v-col cols="12" md="6" class="pb-0 pt-0" >
                    <v-text-field v-model="height" label="ส่วนสูง" type="number"   :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- Weight -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="weight" label="น้ำหนัก"  type="number"   :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- ID Card -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="idCard" label="เลขที่บัตรประชาชน" :rules="[v => !!v || 'Required']"
                        required></v-text-field>
                </v-col>

                <!-- Birth Date -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="birthDate" label="ว/ด/ป เกิด ตามบัตรประชาชน" type="date" :formatter="format"></v-text-field>
                </v-col>

                <!-- Expiry Date -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="expiryDate" label="ว/ด/ป บัตรหมดอายุ ตามบัตรประชาชน"
                        type="date" :formatter="format"></v-text-field>
                </v-col> 

                <!-- Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="address" label="ที่อยู่ตามบัตรประชาชน"></v-textarea>
                </v-col>

                <!-- Contact Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="contactAddress" label="ที่อยู่ตามจัดส่งเอกสาร"></v-textarea>
                </v-col>

               
                <!-- Occupation -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="occupation" label="อาชีพ"></v-text-field>
                </v-col>

                <!-- Monthly Income -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="monthlyIncome" label="รายได้/เดือน" type="number"></v-text-field>
                </v-col>

                <!-- Work Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="workAddress" label="ที่อยู่ที่ทำงาน"></v-textarea>
                </v-col>

                <!-- Contact Number -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="contactNumber" label="เบอร์โทร" type="tel"></v-text-field>
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="email" label="Email" type="email"></v-text-field>
                </v-col>

                <!-- Chronic Disease -->
                <v-col cols="12" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">โรคประจำตัว</span>
                    <v-radio-group v-model="chronicDisease" color="primary" row>
                        <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
                        <v-radio label="มี" value="มี"></v-radio>
                    </v-radio-group>
                </v-col>

                <!-- Disease Details -->
                <v-col cols="12" v-if="chronicDisease === 'มี'" class="pb-0 pt-0">
                    <v-text-field v-model="diseaseDetails" label="กรณีมีโรคประจำตัว โปรดแจ้งชื่อโรค"></v-text-field>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field v-model="emeregencyContact" label="ผู้ที่สามารถติดต่อได้กรณีติดต่อผู้ทำประกันไม่ได้"></v-text-field>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field v-model="beneficiary" label="ชื่อผู้รับผลประโยชน์ อายุ ที่อยู่"></v-text-field>
                </v-col>
                <v-col cols="8" class="pb-0 pt-0">
                    <v-text-field v-model="fatherName" label="ชื่อ-สกุล บิดา ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0 pt-0">
                    <v-text-field v-model="fatherAge" label="บิดาอายุ"></v-text-field>
                </v-col>
                <v-col cols="8" class="pb-0 pt-0">
                    <v-text-field v-model="motherName" label="ชื่อ-สกุล มารดา ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0 pt-0">
                    <v-text-field v-model="motherAge" label="มารดาอายุ"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="spouseName" label="ชื่อ-สกุล คู่สมรส ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="pb-0 pt-0">
                    <v-text-field v-model="spouseAge" label="อายุ คู่สมรส" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="pb-0 pt-0">
                    <v-text-field v-model="spouseBirthDate" label="ว/ด/ป เกิดคู่สมรส"
                        type="date" :formatter="format"></v-text-field>
                </v-col> 
                <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field v-model="hospitalName" label="ชื่อโรงพยาบาลที่ใช้บริการ"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                <span class="text-weight-bold mb-2">การชำระเงิน</span>
                <v-radio-group v-model="billingCycle" color="primary">

                <v-radio label="1 เดือน" value="1 เดือน"></v-radio>
                <v-radio label="3 เดือน" value="3 เดือน"></v-radio>
                <v-radio label="6 เดือน" value="6 เดือน"></v-radio>
                <v-radio label="1 ปี" value="1 ปี"></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="12" md="4" class="pb-0 pt-0">
                <span class="text-weight-bold mb-2">ช่องทางการชำระ</span>
                <v-radio-group v-model="paymentMethod" color="primary" row>
                <v-radio label="บัตรเครดิต" value="CREDIT_CARD"></v-radio>
                <v-radio label="สแกน QR Code" value="QR_CODE"></v-radio>
                </v-radio-group>
                </v-col>
                <v-col cols="6" md="4" v-if="paymentMethod === 'CREDIT_CARD'" class="pb-0 pt-0">
                    <v-text-field v-model="creditCardNo" label="แจ้งเลขบัตร 16 หลัก"></v-text-field>
                </v-col>
                <v-col cols="6" md="4" v-if="paymentMethod === 'CREDIT_CARD'" class="pb-0 pt-0">
                    <v-text-field v-model="creditCardExpire" label="วันหมดอายุบัตรเครดิต ด/ป"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="remark" label="ข้อความถึงตัวแทน (ถ้ามี)"></v-textarea>
                </v-col>
                <v-checkbox
                    v-model="tcAccept"
                    label="ข้าพเจ้ายินยอมให้ตัวแทนบริษัท เอไอเอ จำกัด สามารถเก็บใช้ข้อมูลที่ข้าพเจ้าให้ไว้ เพื่อวัตถุประสงค์ ในการดำเนินการที่เกี่ยวข้องกับผลิตภัณฑ์ หรือแจ้งสิทธิประโยชน์"
                    value="tcAccept"
                    :rules="[v => !!v || 'Required']" required
                ></v-checkbox>



                <!-- Submit Button -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-btn :disabled="!valid" type="submit" color="primary"><v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              class="mr-2"
            ></v-progress-circular>ส่งข้อมูล</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, computed } from "vue";
import axios from '../plugins/axios'; // Import the custom axios instance

export default {
    setup() {
        // Reactive form fields
        const fullNameThai = ref("");
        const fullNameEnglish = ref("");
        const height = ref("");
        const weight = ref("");
        const idCard = ref("");
        const birthDate = ref("");
        const expiryDate = ref("");
        const address = ref("");
        const contactAddress = ref("");
        const idCardFile = ref(null);
        const occupation = ref("");
        const monthlyIncome = ref("");
        const workAddress = ref("");
        const contactNumber = ref("");
        const email = ref("");
        const chronicDisease = ref("ไม่มี");
        const diseaseDetails = ref("");
        const emeregencyContact = ref("");
        const beneficiary = ref("");
        const fatherName = ref("");
        const fatherAge = ref("");
        const motherName = ref("");
        const motherAge = ref("");
        const spouseName = ref("");
        const spouseAge = ref("");
        const spouseBirthDate = ref("");
        const hospitalName = ref("");
        const billingCycle = ref("");
        const paymentMethod = ref("");
        const creditCardNo = ref("");
        const creditCardExpire = ref("");
        const remark = ref("");
        const tcAccept = ref("");
        const snackbar = ref(false); // Controls the visibility of the snackbar
        const snackbarMessage = ref(''); // Holds the message to display in the snackbar
        const loading = ref(false); // Loading state

        // Construct formData dynamically


        // Form validation state
        const valid = ref(false);

        const uploadFile = async () => {
            if (!idCardFile.value) {
                snackbarMessage.value = "กรุณาแนบรูป";
                snackbar.value = true;
                return;
            }

            const formData = new FormData();
            formData.append("IDCardFile", idCardFile.value);

            try {
                const response = await axios.post("/ocr", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const data = response.data;

                // Prefill form fields with extracted data
                idCard.value = data.idCard || "";
                birthDate.value = data.birthDate || "";
                fullNameThai.value = data.fullNameThai || "";
                fullNameEnglish.value = data.fullNameEnglish || "";
                address.value = data.address || "";
                contactAddress.value = data.address || "";
                expiryDate.value = data.expiryDate || "";

                //alert("Form prefilled with extracted data!");
            } catch (error) {
                console.error("Error uploading file:", error);
                snackbarMessage.value = "เกิดข้อผิดพลาด";
                snackbar.value = true;
            }
        };
        // Form submission
        
        const submitForm = async () => {
            if (valid.value) {

                loading.value = true; // Set loading to true
                const formData = new FormData();

                // Append text fields
                formData.append("FullNameThai", fullNameThai.value);
                formData.append("FullNameEnglish", fullNameEnglish.value);
                formData.append("Height", height.value);
                formData.append("Weight", weight.value);
                formData.append("IDCard", idCard.value);
                formData.append("BirthDate", birthDate.value);
                formData.append("ExpiryDate", expiryDate.value);
                formData.append("Address", address.value);
                formData.append("ContactAddress", contactAddress.value);
                formData.append("Occupation", occupation.value);
                formData.append("MonthlyIncome", monthlyIncome.value);
                formData.append("WorkAddress", workAddress.value);
                formData.append("ContactNumber", contactNumber.value);
                formData.append("Email", email.value);
                formData.append("ChronicDisease", chronicDisease.value);
                formData.append("DiseaseDetails", diseaseDetails.value);
                formData.append("Beneficiary", beneficiary.value);
         formData.append("FatherName", fatherName.value);
         formData.append("FatherAge", fatherAge.value);
         formData.append("MotherName", motherName.value);
         formData.append("MotherAge", motherAge.value);
         formData.append("SpouseName", spouseName.value);
         formData.append("SpouseAge", spouseAge.value);
         formData.append("SpouseBirthDate", spouseBirthDate.value);
         formData.append("HospitalName", hospitalName.value);
         formData.append("BillingCycle", billingCycle.value);
         formData.append("PaymentMethod", paymentMethod.value);
         formData.append("CreditCardNo", creditCardNo.value);
         formData.append("CreditCardExpire", creditCardExpire.value);
         formData.append("Remark", remark.value);
         formData.append("TcAccpet", tcAccept.value);
                if (idCardFile.value) {
                    console.log("Selected file:", idCardFile.value); // Debugging purpose
                    formData.append("IDCardFile", idCardFile.value);
                } else {
                    console.error("No file selected!");
                }
                try {
                    const response = await axios.post("customer_information", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });
                    snackbarMessage.value = "ขอบคุณค่ะ เราจะติดต่อกลับไป";
                    snackbar.value = true;
                    
                } catch (error) {
                    console.error("Error submitting form:", error);
                    snackbarMessage.value = "เกิดข้อผิดพลาด";
                    snackbar.value = true;
                }finally {
                    loading.value = false; // Set loading to false
                }
            } else {
                snackbarMessage.value = "กรุฯากรอกข้อมูลให้ครบถ้วน";
                snackbar.value = true;
            }
        };
        
        

        return {
            fullNameThai,
            fullNameEnglish,
            height,
            weight,
            idCard,
            birthDate,
            expiryDate,
            address,
            contactAddress,
            idCardFile,
            occupation,
            monthlyIncome,
            workAddress,
            contactNumber,
            email,
            chronicDisease,
            diseaseDetails,
            valid,
            submitForm,
            uploadFile,
            emeregencyContact,
         beneficiary,
         fatherName,
         fatherAge,
         motherName,
         motherAge,
         spouseName,
         spouseAge,
         spouseBirthDate,
         hospitalName,
         billingCycle,
         paymentMethod,
         creditCardNo,
         creditCardExpire,
         remark,
         tcAccept,
            snackbarMessage,
            snackbar,
            bannerApplicationForm: '/application-form.jpg',
            loading
        };
    },
};
</script>